export default {
  methods: {
    getProfile() {
      return this.$api.get('profile')
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    changeProfile(params) {
      return this.$api.post('profile', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}
