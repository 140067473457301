import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",staticClass:"rcc-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"rcc-form__form-body"},[(_vm.isSupplierRole)?[_c('rcc-text-input',{attrs:{"label":"Имя","width":"40%","rules":[function (value) { return _vm.isRequiredField({ value: value, field: 'Имя' }); }]},model:{value:(_vm.form.fio),callback:function ($$v) {_vm.$set(_vm.form, "fio", $$v)},expression:"form.fio"}}),_c('rcc-text-input',{attrs:{"label":"Email","width":"40%","rules":[function (value) { return _vm.isRequiredField({ value: value, field: 'Email' }); }]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]:_vm._e(),(!_vm.isSupplierRole)?_c('section',[_c('h2',[_vm._v(" Оповещения ")]),_c('rcc-checkbox',{attrs:{"label":"Получать оповещения на почту"},model:{value:(_vm.form.send_email),callback:function ($$v) {_vm.$set(_vm.form, "send_email", $$v)},expression:"form.send_email"}})],1):_vm._e(),_c('section',[_c('h2',[_vm._v(" Сменить пароль ")]),_c('rcc-text-input',{attrs:{"label":"Новый пароль","width":"40%","type":"password","rules":[
          function (value) { return (!_vm.passwordRepeat) || _vm.isRequiredField({ value: value, field: 'Новый пароль' }); }
        ]},on:{"input":_vm.handlePasswordInput},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('rcc-text-input',{ref:"passwordRepeat",attrs:{"label":"Новый повторно","width":"40%","type":"password","rules":[
          function (value) { return (!_vm.passwordRepeat) || _vm.isRequiredField({ value: value, field: 'Повтор пароля' }); },
          _vm.passwordRepeatValidate
        ]},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}})],1)],2),_c('rcc-footer-buttons',{attrs:{"is-submit":_vm.isSubmit},on:{"cancel-click":function($event){return _vm.$router.push({ name: 'returns' })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }